<template>
  <v-component
    :ref="id"
    v-bind:is="component"
    v-bind="bindings"
    v-model="currentValue"
    @keyup.enter="save"
    @keyup.esc="reset"
    :disabled="!canEdit"
    @update:error="setError"
  >
    <template v-if="hasSlot.item" v-slot:item="itemBindings">
      <slot name="item" v-bind="itemBindings"> </slot>
    </template>

    <template v-slot:label>
      <span :class="'inline--label inline--label--' + inputType">
        {{ bindings.label }}
      </span>
    </template>
    <template v-slot:append>
      <ActionsConfirmCancel
        v-show="canEdit"
        :hidden="initialValue == currentValue && !loading"
        @cancel="reset"
        @save="save"
        :loading="loading"
        :canSave="!hasError && formValid"
      />
    </template>
  </v-component>
</template>

<script>
import { computed, ref, watch } from "vue-demi";
import { generateRandomId, createBindings } from "../utils";

import ActionsConfirmCancel from "./ActionsConfirmCancel.vue";

export default {
  name: "InlineEdit",
  props: {
    value: {
      default: () => "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
    formValid: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    ActionsConfirmCancel,
  },
  setup(props, ctx) {
    // Get the value with the correct type
    const getVal = (val) => {
      if (props.inputType === "checkbox") return !!val;
      return val;
    };
    // Create an ID for this field
    const id = ref(generateRandomId());
    const val = getVal(props.value);
    // Set values
    const initialValue = ref(val);
    const currentValue = ref(val);
    const hasError = ref(false);

    const blur = () => {
      if (ctx.refs[id.value]?.blur) ctx.refs[id.value].blur();
    };

    // Create methods
    const setError = (val) => {
      hasError.value = val;
    };

    const reset = () => {
      blur();
      currentValue.value = initialValue.value;
    };
    const save = () => {
      blur();
      // Validate all fields before setting the value
      if (ctx.refs[id.value]?.form?.validate() === false) return;
      ctx.emit("input", currentValue.value);
    };
    const updateValue = (value) => ctx.emit("input", value);

    // Define component types
    const comoponents = {
      text: () => import(`vuetify/lib/components/VTextField`),
      checkbox: () =>
        import(`vuetify/lib/components/VCheckbox`).then(
          (module) => module.VCheckbox
        ),
      select: () => import(`vuetify/lib/components/VSelect`),
    };

    // Watch for value changes
    watch(
      () => props.value,
      (newVal) => {
        const val = getVal(newVal);
        if (val != initialValue.value) {
          // Update the initial value
          initialValue.value = val;
          // For now, we'll just update the current value as well
          // TODO: verify what happens for multiple editing at the same time
          currentValue.value = val;
        }
      },
      { immediate: true }
    );

    const bindings = createBindings([ctx.attrs, props], ["value"]);

    const hasSlot = computed(() => {
      return {
        item: !!ctx.slots.item,
      };
    });

    return {
      id,
      component: comoponents[props.inputType],
      hasSlot,
      hasError,
      initialValue,
      currentValue,
      reset,
      save,
      updateValue,
      setError,
      bindings,
    };
  },
};
</script>

<style lang="scss" scoped>
$text-color-here: #000;
::v-deep.theme--light {
  &.v-label.v-label--is-disabled:not(.v-input--checkbox),
  &.v-input--is-disabled:not(.v-input--checkbox),
  &.v-input--is-disabled input,
  &.v-input--is-disabled textarea,
  .inline--label:not(.inline--label--checkbox) {
    color: $text-color-here;
  }
}
</style>
