import { computed, unref } from "vue-demi";
import { useFind } from "feathers-pinia";

/***
 * Same as feathers-pinia useFind, but creates a new record if none are found
 */
export const useFindOrCreate = (useFindParams) => {
  const createEntity = () => {
    const params = unref(useFindParams.params);
    // Create a new entity
    const newEntity = new useFindParams.model({
      ...unref(params.query), // Use query params as defaults
    });
    // Add new entity to the store
    useFindParams.model.store.addToStore(newEntity);
    // Return the new entity
    return newEntity;
  };
  // Get the results object
  const results = useFind(useFindParams);
  // Computed items
  const computes = {
    // Determine which item to use
    item: computed(() => {
      const items = results.items?.value ?? [];
      // If results were found, return the first item
      if (items.length) return items[0];
      // If results have finished loading, create a new entity
      if (results.haveLoaded?.value) return createEntity();
      // Otherwise, return null
      return null;
    }),
  };

  return {
    ...results,
    ...computes,
  };
};
