<template>
  <div class="action--confirm-deny">
    <v-scroll-y-transition>
      <v-btn
        v-show="!hidden"
        color="red"
        v-if="!loading"
        @click="cancel"
        icon
        small
        class="mb-1 mr-1"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <v-btn
        v-show="!hidden"
        :color="color"
        :loading="loading"
        @click="save"
        icon
        small
        class="mb-1"
        :disabled="!canSave"
      >
        <v-icon> mdi-check </v-icon>
      </v-btn>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import { ref } from "vue-demi";

export default {
  name: "ActionsConfirmCancel",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const color = ref("success");
    // Methods
    const save = () => emit("save");
    const cancel = () => emit("cancel");

    return {
      color,
      save,
      cancel,
    };
  },
};
</script>

<style lang="scss">
.action--confirm-deny {
  min-width: 4rem;
}
</style>
