<template>
  <v-container>
    <v-card class="mb-5">
      <v-card-title>Settings</v-card-title>
      <v-container>
        <v-card>
          <v-card-title>Default Facility</v-card-title>
          <v-container>
            <InlineFieldsForm
              v-if="defaultFacilityPreference"
              :entity="defaultFacilityPreference"
              :entityStore="userSettingsStore"
              :canEdit="true"
              :fields="['value']"
              noBadge
            >
              <template v-slot:default="{ childProps, childEvents }">
                <v-container>
                  <InlineEdit
                    v-bind="childProps.value"
                    v-on="childEvents.value"
                    label="Default Facility"
                    inputType="select"
                    :items="facilitiesStore.activeFacilities"
                    item-text="code"
                    item-value="id"
                    outlined
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.code }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.title }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </InlineEdit>
                </v-container>
              </template>
            </InlineFieldsForm>
          </v-container>
        </v-card>
      </v-container>
    </v-card>
    <v-card>
      <v-card-title>Permissions</v-card-title>
      <v-container>
        <AdminUsersPermissionsFacilitiesVue :userId="userId" />
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { computed, ref, watch } from "vue-demi";
import { useFind } from "feathers-pinia";
import { useFindOrCreate } from "../utils/use-find-or-create";
import { useUserSettings } from "@/store/userSettings.pinia.js";
import { useFacilities } from "@/store/facilities.pinia";
import AdminUsersPermissionsFacilitiesVue from "@/components/AdminUsersPermissionsFacilities.vue";
import InlineFieldsForm from "@/components/InlineFieldsForm.vue";
import InlineEdit from "@/components/InlineEdit.vue";

export default {
  name: "AdminUsersSettings",
  components: {
    AdminUsersPermissionsFacilitiesVue,
    InlineFieldsForm,
    InlineEdit,
  },
  props: {
    userId: {
      default: () => null,
    },
  },
  setup(props) {
    const facilitiesStore = useFacilities();
    const userSettingsStore = useUserSettings();
    const userId = ref(props.userId);
    const defaultFacilitySettingsId = ref(null);

    const userSettingParams = computed(() => ({
      query: { user_id: userId.value },
    }));

    const { items: userSettings, isPending: userSettingsPending } = useFind({
      model: userSettingsStore.Model,
      params: userSettingParams,
    });

    const userSettingDefaultFacilityParams = computed(() => ({
      query: { user_id: userId.value, property: "default_facility_id" },
    }));

    const { item: defaultFacilityPreference } = useFindOrCreate({
      model: userSettingsStore.Model,
      params: userSettingDefaultFacilityParams,
    });

    watch(
      () => userSettings.value,
      (val) => {
        const defaultFacility =
          val?.find((item) => item.property === "default_facility_id") ?? false;
        if (defaultFacility) {
          defaultFacilitySettingsId.value = defaultFacility.id;
        }
      },
      { immediate: true }
    );

    watch(
      () => props.userId,
      (newId) => (userId.value = newId)
    );

    return {
      facilitiesStore,
      userSettingsStore,
      userSettingsPending,
      defaultFacilityPreference,
    };
  },
};
</script>
